import tw from "twin.macro";

import { styled } from "@/app/core/styles/stitches.config";
import { Text } from "@/app/core/ui/components/Text";

export const HtmlText = styled(Text, {
  p: { ...tw`mb-0.5` },
  strong: { ...tw`font-bold` },
  ul: { listStyleType: "disc", paddingLeft: "25px", marginTop: "15px" },
  "a[href]": { ...tw`text-accent-faded underline` },
  h2: { ...tw`font-bold mt-2 mb-1 text-lg` },
  "h3,h4": { ...tw`font-bold mt-2 mb-0.5` },
  table: {
    "border-spacing": 0,
    "border-collapse": "initial",
    ...tw`border border-gray-200 rounded-md`,
    td: tw`px-2 py-0.75 border-b border-gray-200`,
    "tr td:first-child": tw`bg-gray-150 font-medium`,
    "tr td:last-child": tw`px-2`,
    "tr:last-child td": tw`border-b-0`,
    tr: tw`divide-x divide-gray-200`,
  },
});
