import tw from "twin.macro";

import { styled } from "@/app/core/styles/stitches.config";
import { Flex } from "@/app/core/ui/components/Flex";
import { Text } from "@/app/core/ui/components/Text";

const CardComponent = styled(Flex, {
  ...tw`bg-white rounded-lg`,
  variants: {
    padding: {
      true: tw`p-3`,
      false: {},
    },
    borderless: {
      true: {},
      false: tw`border border-gray-200`,
    },
    width: {
      full: tw`w-full`,
      auto: {},
    },
    overflow: {
      hidden: tw`overflow-hidden`,
      none: {},
      visible: tw`overflow-visible`,
    },
  },
  defaultVariants: {
    padding: true,
    borderless: false,
    width: "full",
    overflow: "hidden",
  },
});

interface CardProps extends React.ComponentProps<typeof CardComponent> {
  title?: string;
}

export const Card: React.FC<CardProps> = ({ title, children, ...props }) => {
  return (
    <CardComponent padding={Boolean(!title)} css={title ? tw`flex-col` : {}} {...props}>
      {title ? (
        <>
          <Flex tw="w-full px-3 py-1.75 bg-gray-100 border-b border-gray-200">
            <Text size="lg" weight="bold" color="gray-700">
              {title}
            </Text>
          </Flex>
          <Flex tw="p-3" {...props}>
            {children}
          </Flex>
        </>
      ) : (
        children
      )}
    </CardComponent>
  );
};
