import React from "react";

import * as lucideIcons from "lucide-react";

type LucideProps = lucideIcons.LucideProps;
interface IconRendererProps extends LucideProps {
  icon: keyof typeof lucideIcons | string;
  title?: string;
}

export const IconRenderer: React.FC<IconRendererProps> = ({ icon, ...props }) => {
  const Icon = lucideIcons[icon as keyof typeof lucideIcons] as lucideIcons.LucideIcon;
  return <>{React.cloneElement(<Icon />, props)}</>;
};
