import React from "react";

interface ForcemanagerLogoProps extends React.SVGProps<SVGSVGElement> {}

export const ForcemanagerLogo: React.FC<ForcemanagerLogoProps> = (props) => {
  return (
    <svg viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.1911 15.6797C22.724 14.7557 22.8684 13.6579 22.5926 12.6274C22.3168 11.5969 21.6433 10.7181 20.7201 10.1837C19.7957 9.65064 18.6974 9.50634 17.6667 9.78254C16.636 10.0587 15.757 10.7329 15.2231 11.6567C14.9583 12.1138 14.7862 12.6186 14.7168 13.1422C14.6474 13.6658 14.6819 14.198 14.8185 14.7082C14.955 15.2185 15.1909 15.6968 15.5126 16.1157C15.8342 16.5347 16.2354 16.8861 16.6931 17.1497C17.1505 17.4145 17.6557 17.5865 18.1796 17.656C18.7036 17.7254 19.2361 17.6908 19.7467 17.5543C20.2574 17.4178 20.7361 17.182 21.1555 16.8603C21.5749 16.5387 21.9268 16.1375 22.1911 15.6797ZM17.2811 24.5287C17.5459 24.0713 17.7179 23.5662 17.7873 23.0422C17.8568 22.5183 17.8221 21.9858 17.6855 21.4752C17.5489 20.9646 17.3129 20.486 16.9911 20.0667C16.6693 19.6474 16.2679 19.2957 15.8101 19.0317C14.8855 18.4986 13.7871 18.3543 12.7562 18.6305C11.7253 18.9067 10.8462 19.5808 10.3121 20.5047C9.80688 21.427 9.6828 22.5104 9.96637 23.523C10.2499 24.5356 10.9186 25.397 11.8293 25.9228C12.74 26.4485 13.8203 26.597 14.839 26.3362C15.8577 26.0755 16.7339 25.4264 17.2801 24.5277L17.2811 24.5287ZM25.6271 25.9977C25.1698 26.2627 24.6648 26.4349 24.1409 26.5045C23.6171 26.574 23.0846 26.5395 22.5741 26.403C22.0636 26.2664 21.585 26.0305 21.1658 25.7087C20.7466 25.3869 20.3949 24.9856 20.1311 24.5277C19.866 24.0704 19.6938 23.5653 19.6244 23.0413C19.5549 22.5173 19.5895 21.9848 19.7263 21.4742C19.863 20.9636 20.0992 20.4851 20.4213 20.0659C20.7433 19.6468 21.1449 19.2954 21.6031 19.0317C22.5277 18.4987 23.6262 18.3545 24.6571 18.6309C25.688 18.9073 26.5671 19.5816 27.1011 20.5057C27.365 20.9631 27.5362 21.468 27.605 21.9916C27.6738 22.5151 27.6387 23.0471 27.5018 23.5571C27.365 24.0671 27.1289 24.5452 26.8072 24.9639C26.4856 25.3827 26.0845 25.734 25.6271 25.9977Z"
        fill="#FF8C00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.6711 34.4967C29.9901 34.4967 34.3171 30.1707 34.3171 24.8537V12.9197C34.3171 7.60275 29.9901 3.27775 24.6711 3.27775H12.7341C7.41605 3.27775 3.09005 7.60275 3.09005 12.9197V24.8537C3.09005 30.1707 7.41605 34.4957 12.7341 34.4957L24.6711 34.4967ZM12.7341 0.886745H24.6711C31.3111 0.886745 36.7141 6.28475 36.7141 12.9197V24.8537C36.7141 31.4887 31.3121 36.8867 24.6711 36.8867H12.7341C6.09705 36.8867 0.697052 31.4887 0.697052 24.8537V12.9197C0.697052 6.28475 6.09705 0.886745 12.7341 0.886745Z"
        fill="#7D8A96"
      />
    </svg>
  );
};
