import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Popover } from "@headlessui/react";
import DOMPurify from "isomorphic-dompurify";
import {
  Building2,
  CheckCircle2,
  Clock,
  Delete,
  Edit3,
  Flame,
  Link2,
  MapPin,
  MapPinOff,
  MoreVertical,
  Undo,
  User,
} from "lucide-react";
import { DateTime } from "luxon";
import Link from "next/link";
import { useRouter } from "next/router";
import tw, { theme } from "twin.macro";

import { ForcemanagerLogo } from "@/app/assets/components/ForcemanagerLogo";
import { TooltipContent } from "@/app/core/display/tooltip/components/TooltipContent";
import { TooltipTrigger } from "@/app/core/display/tooltip/components/TooltipTrigger";
import { Tooltip } from "@/app/core/display/tooltip/Tooltip";
import { routes } from "@/app/core/http/routes";
import { Badge } from "@/app/core/ui/components/Badge";
import { Box } from "@/app/core/ui/components/Box";
import { Button } from "@/app/core/ui/components/Button";
import { Card } from "@/app/core/ui/components/Card";
import { Flex } from "@/app/core/ui/components/Flex";
import { HtmlText } from "@/app/core/ui/components/HtmlText";
import { IconRenderer } from "@/app/core/ui/components/IconRenderer";
import { PopoverItem } from "@/app/core/ui/components/popover/PopoverItem";
import { PopoverPanel } from "@/app/core/ui/components/popover/PopoverPanel";
import { Text } from "@/app/core/ui/components/Text";
import { date } from "@/app/core/utils";
import { config } from "@/app/core/utils/config";
import { getActivityTypeMeta, isActivityKind } from "@/app/crm/activity/activity-utils";
import { UserAvatar } from "@/app/user/components/UserAvatar";
import { useUser } from "@/app/user/hooks/useUser";
import type { ILeadActivity, IPartnerActivity } from "@obd/common";

interface ActivityCardProps {
  activity: ILeadActivity | IPartnerActivity;
  isFirst?: boolean;
  isLast?: boolean;
  isLonely?: boolean;
  isLate?: boolean;
  isLocked?: boolean;
  type?: "timeline" | "flat";
  onComplete?: () => void;
  onClick?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  onRestore?: () => void;
  bodyFallback?: string;
  showTarget?: boolean;
}

export const ActivityCard: React.FC<ActivityCardProps> = ({
  activity,
  isFirst,
  isLast,
  isLonely,
  type = "timeline",
  isLate,
  onComplete,
  onClick,
  onEdit,
  onDelete,
  onRestore,
  bodyFallback,
  showTarget = false,
}) => {
  const { data: user } = useUser();

  const isLeadActivity = isActivityKind(activity, "lead");
  const isDeleted = isLeadActivity && activity.deleted;

  const [isShowingExtendedBody, setIsShowingExtendedBody] = useState(false);

  const { asPath } = useRouter();

  const getActivityHash = useCallback(
    (id: number) => {
      return `activity-${isLeadActivity ? "lead" : "partner"}-${id}`;
    },
    [isLeadActivity]
  );

  const getActivityLink = useCallback(
    (id: number, providedHash?: string) => {
      const hash = providedHash ?? getActivityHash(id);
      const [pathWithQuery] = asPath.split("#");
      const [path, query] = pathWithQuery.split("?");

      if (!query) {
        return [path, hash].join("#");
      }

      return [[path, query].join("?"), hash].join("#");
    },
    [asPath, getActivityHash]
  );

  const isFocused = useMemo(
    () => asPath.split("#")[1] === getActivityHash(activity.id),
    [activity.id, asPath, getActivityHash]
  );

  const hasHandledFocus = useRef(false);

  useEffect(() => {
    const [_, hash] = asPath.split("#");
    if (hash && !hasHandledFocus.current) {
      hasHandledFocus.current = true;
      const a = document.createElement("a");
      a.href = getActivityLink(-1, location.hash.replace("#", ""));
      a.click();
    }
  }, [asPath, getActivityLink]);

  /**
   * The activity metadata
   */
  const heading = useMemo(() => {
    const meta = getActivityTypeMeta(activity.type, activity);

    if (isLeadActivity && activity.dueAt !== null && activity.supersededBy === null) {
      meta.actionName = `programó ${
        meta.actionName.split(" ")[meta.actionName.split(" ").length - 1]
      }`;

      if (isLate) {
        meta.fg = "#DC3D43";
        meta.bg = "#FFEFEF";
        meta.expiryDate = `Expiró ${date.format(activity.dueAt, "relative")}`;
      }
    } else if (
      (!isLeadActivity || (user?.role?.id === "commercial" && activity.dueAt === null)) &&
      activity.type === "commercialVisit"
    ) {
      meta.actionName = "realizó una";
    }

    let firstName = "Oblido";
    let lastName = "";

    if (activity.createdByRaw) {
      firstName = activity.createdByRaw;
      lastName = "";
    } else if (activity.createdByCommercial) {
      firstName = activity.createdByCommercial.name;
      lastName = "";
    } else if (isLeadActivity && activity.createdByLeadManager) {
      firstName = activity.createdByLeadManager.user.firstName;
      lastName = activity.createdByLeadManager.user.lastName;
    } else if (activity.createdByUser) {
      firstName = activity.createdByUser.firstName;
      lastName = activity.createdByUser.lastName;
    }

    const actionUser = [firstName, lastName].filter(Boolean).join(" ");

    return {
      ...meta,
      user: actionUser,
    };
  }, [activity, isLate, isLeadActivity, user?.role?.id]);

  const progressStyle = useMemo(() => {
    if (isLonely) {
      return tw`h-0`;
    } else if (isFirst) {
      return tw`top-3`;
    } else if (isLast) {
      return tw`h-3`;
    }
  }, [isFirst, isLast, isLonely]);

  const createdAtDateTime = DateTime.fromJSDate(new Date(activity.createdAt)).setLocale(
    "es-ES"
  );

  const canBeCompleted =
    isLeadActivity &&
    !isDeleted &&
    onComplete &&
    activity.dueAt !== null &&
    activity.supersededBy === null &&
    (!activity.assignedCommercial || activity.assignedCommercial.userId === user?.id);

  const defaultBadgeProps: React.ComponentProps<typeof Badge> = {
    size: "sm",
    color: "gray-800",
    weight: "medium",
    css: tw`bg-white border border-gray-300 shadow-sm`,
  };

  return (
    <Flex key={activity.id} tw="text-md pb-2 relative">
      <Box
        id={getActivityHash(activity.id)}
        tw="absolute -top-[10rem] invisible opacity-0 pointer-events-none"
      />

      {type === "timeline" && (
        <>
          <Box
            tw="w-0.25 bg-gray-200 h-full absolute top-0 left-[1.125rem]"
            css={{ ...progressStyle }}
          />
        </>
      )}

      <Flex tw="h-5 items-center w-4.5 -ml-0.75">
        <Box
          tw="relative z-10 w-4 h-4 rounded-full border-4 border-gray-50 bg-gray-150 text-gray-700"
          onClick={onClick}
          css={{
            backgroundColor: isDeleted ? undefined : heading.bg,
            color: isDeleted ? undefined : heading.fg,
            ...(onClick ? tw`cursor-pointer` : {}),
          }}
        >
          <Flex
            center
            tw="w-full h-full rounded-full transition-all"
            css={{
              border: `1px solid ${
                isDeleted
                  ? theme("colors.gray.300")
                  : `${heading.fg}${isFocused ? "40" : "20"}`
              }`,
              ...(isFocused
                ? {
                    boxShadow: `0px 7px 13px 0px ${heading.fg}50`,
                  }
                : {}),
            }}
          >
            {React.cloneElement(<heading.icon />, {
              size: "13",
            })}
          </Flex>
        </Box>
      </Flex>

      <Flex tw="flex-col px-0.75 w-full">
        <Flex tw="flex-wrap items-start gap-1 py-1.5 justify-between">
          <Flex tw="items-center gap-0.75">
            <UserAvatar
              tw="flex-shrink-0 border border-gray-200 shadow-sm"
              size="xs"
              fromUserId={
                activity.createdByUser?.id ??
                (heading.user === "Oblido" ? config.oblidoUserId : undefined)
              }
              from={heading.user}
              background="gradient"
            />

            <Text
              tw="text-[1.25rem]"
              css={{
                ...(isDeleted
                  ? tw`line-through [text-decoration-color: theme(colors.gray.600)] text-gray-700`
                  : {}),
              }}
            >
              <Text as="span" weight="medium">
                {heading.user}
              </Text>{" "}
              <Text as="span" color="gray-700">
                {heading.actionName}
              </Text>{" "}
              <Text as="span" weight="medium">
                {heading.activityName.toLowerCase()}
              </Text>
              {activity.extra && (
                <>
                  {" "}
                  <Text as="span" color="gray-700">
                    {activity.extra}
                  </Text>
                </>
              )}{" "}
              <Link
                tw="text-gray-700 hover:(underline text-accent-faded)"
                href={getActivityLink(activity.id)}
                color="gray-700"
                title={createdAtDateTime.toFormat("dd/MM/yyyy HH:mm:ss")}
              >
                {createdAtDateTime.diff(DateTime.now().minus({ month: 1 })).milliseconds >
                0
                  ? date.format(activity.createdAt, "relative")
                  : `el ${createdAtDateTime.toLocaleString({
                      month: "short",
                      day: "numeric",
                      year:
                        createdAtDateTime.get("year") !== new Date().getFullYear()
                          ? "numeric"
                          : undefined,
                    })}`}
              </Link>
              {!isDeleted && isLeadActivity && activity.edited && (
                <Edit3 size="11" tw="inline-block ml-0.75 text-gray-700" />
              )}
            </Text>
          </Flex>

          {!isDeleted ? (
            <Flex tw="items-center gap-0.5 flex-wrap pl-2.5">
              {heading.expiryDate && isLeadActivity && (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Badge {...defaultBadgeProps}>
                      <Flame size="12" tw="text-red-500" /> {heading.expiryDate}
                    </Badge>
                  </TooltipTrigger>
                  <TooltipContent smaller>
                    {date.format(activity.dueAt!, "dd/MM/yyyy HH:mm")}
                  </TooltipContent>
                </Tooltip>
              )}

              {!isLeadActivity && showTarget && (
                <Link href={routes.partners.id(activity.partner.id)}>
                  <Badge {...defaultBadgeProps}>
                    <Building2 size="12" />
                    {activity.partner.name}
                  </Badge>
                </Link>
              )}

              {!isLeadActivity && activity.isFromForceManager && (
                <Badge {...defaultBadgeProps}>
                  <ForcemanagerLogo width={13} />
                  ForceManager
                </Badge>
              )}

              {isLeadActivity && activity.supersededActivity?.id !== undefined && (
                <Link
                  className="group"
                  href={getActivityLink(activity.supersededActivity.id)}
                >
                  <Badge
                    {...defaultBadgeProps}
                    tw="transition-all group-hover:(shadow-lg)"
                  >
                    <Link2 size="12" />#{activity.supersededActivity.id}
                  </Badge>
                </Link>
              )}

              {isLeadActivity && activity.assignedCommercial && (
                <Badge {...defaultBadgeProps}>
                  <User size="12" />
                  {activity.assignedCommercial.name}
                </Badge>
              )}

              {isLeadActivity && !isLate && activity.dueAt !== null && (
                <Badge {...defaultBadgeProps}>
                  <Clock size="12" />
                  {date.format(activity.dueAt, "dd/MM/yyyy HH:mm")}
                </Badge>
              )}

              {!isLeadActivity && activity.type === "commercialVisit" && (
                <>
                  {activity.latitude && activity.longitude ? (
                    <Link
                      target="_blank"
                      href={routes.external.googleMaps.coordinates({
                        latitude: activity.latitude,
                        longitude: activity.longitude,
                      })}
                    >
                      <Badge {...defaultBadgeProps}>
                        <MapPin size="12" tw="text-accent" />
                        Geolocalizada
                      </Badge>
                    </Link>
                  ) : (
                    <Badge {...defaultBadgeProps} color="gray-700">
                      <MapPinOff size="12" />
                      No geolocalizada
                    </Badge>
                  )}
                </>
              )}

              {!isLeadActivity && activity.commercialCampaign && (
                <Tooltip placement="top-end">
                  <TooltipTrigger asChild>
                    <Badge {...defaultBadgeProps}>
                      <IconRenderer
                        icon={activity.commercialCampaign.icon}
                        size="13"
                        tw="text-[#FC641A]"
                      />
                      Campaña #{activity.commercialCampaign.id}
                    </Badge>
                  </TooltipTrigger>
                  <TooltipContent>{activity.commercialCampaign.name}</TooltipContent>
                </Tooltip>
              )}

              {isLeadActivity && activity.supersededBy && activity.dueAt && (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Link
                      className="group"
                      href={getActivityLink(activity.supersededBy.id)}
                    >
                      <Badge
                        {...defaultBadgeProps}
                        tw="transition-all group-hover:(shadow-lg)"
                      >
                        <CheckCircle2
                          size="13"
                          tw="text-success"
                          css={
                            date.compareAsc(
                              activity.dueAt,
                              activity.supersededBy.createdAt
                            ) === -1
                              ? tw`text-orange-600`
                              : tw`text-success`
                          }
                        />
                        {date.compareAsc(
                          activity.dueAt,
                          activity.supersededBy.createdAt
                        ) === -1
                          ? "Tarde via"
                          : "Via"}{" "}
                        #{activity.supersededBy.id}
                      </Badge>
                    </Link>
                  </TooltipTrigger>
                  <TooltipContent>
                    {date.format(activity.supersededBy.createdAt, "dd/MM/yyyy HH:mm")}
                  </TooltipContent>
                </Tooltip>
              )}

              {isLeadActivity &&
                activity.createdByUser &&
                activity.createdByUser?.id === user?.id &&
                ["note", "commercialVisit", "call"].includes(activity.type) &&
                (onEdit || onDelete) && (
                  <Popover tw="relative z-20">
                    {({ open }) => (
                      <>
                        <Popover.Button tw="flex items-center justify-end text-gray-600 hover:text-gray-800 outline-none">
                          <MoreVertical size="16" />
                        </Popover.Button>

                        <PopoverPanel open={open} tw="top-full right-0 min-w-[15rem]">
                          {onEdit && (
                            <PopoverItem tw="px-0.75 py-0.5 gap-1" onClick={onEdit}>
                              <Edit3 size="13" tw="text-gray-600" />
                              <Text size="sm">Editar</Text>
                            </PopoverItem>
                          )}
                          {onDelete && (
                            <PopoverItem tw="px-0.75 py-0.5 gap-1">
                              <Delete size="13" tw="text-red-500" />
                              <Text size="sm" tw="text-red-500" onClick={onDelete}>
                                Eliminar
                              </Text>
                            </PopoverItem>
                          )}
                        </PopoverPanel>
                      </>
                    )}
                  </Popover>
                )}
            </Flex>
          ) : (
            activity.createdByUser &&
            activity.createdByUser.id === user?.id &&
            onRestore && (
              <Flex
                as="button"
                tw="flex items-center text-sm text-gray-700 gap-0.5 hover:underline"
                onClick={onRestore}
              >
                <Undo size="12" />
                Restaurar actividad
              </Flex>
            )
          )}
        </Flex>

        {!isDeleted && (activity.body || bodyFallback) ? (
          <Card
            tw="flex-col p-1.75 [box-shadow: 2px 2px 5px 0 rgb(0 0 0 / 0.05), 0 2px 2px 0px rgb(0 0 0 / 0.075)] w-full border border-gray-200"
            borderless
            padding={false}
            css={{
              ...(isDeleted ? tw`bg-gray-150` : {}),
            }}
          >
            <Text tw="[white-space: pre-line]" color="gray-900">
              {/* eslint-disable-next-line no-nested-ternary */}
              {activity.body ? (
                activity.isHTML ? (
                  <HtmlText
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        isShowingExtendedBody && activity.extendedBody
                          ? activity.extendedBody
                          : activity.body,
                        { FORBID_TAGS: ["style"] }
                      ),
                    }}
                  />
                ) : (
                  activity.body
                )
              ) : (
                bodyFallback
              )}
            </Text>

            {activity.extendedBody && (
              <button
                tw="self-start mt-1 text-accent-faded text-sm font-medium hover:(underline)"
                onClick={() => setIsShowingExtendedBody((s) => !s)}
              >
                Ver {isShowingExtendedBody ? "menos" : "más"}
              </button>
            )}

            {canBeCompleted && (
              <Button
                size="md"
                theme="light-gray"
                tw="mt-2 self-start [box-shadow: 2px 2px 5px 0 rgb(0 0 0 / 0.05), 0 2px 2px 0px rgb(0 0 0 / 0.075)]"
                onClick={onComplete}
              >
                Marcar como completada
              </Button>
            )}
          </Card>
        ) : canBeCompleted ? (
          <Button
            size="md"
            theme="white"
            tw="self-start [box-shadow: 2px 2px 5px 0 rgb(0 0 0 / 0.05), 0 2px 2px 0px rgb(0 0 0 / 0.075)]"
            onClick={onComplete}
          >
            Marcar como completada
          </Button>
        ) : null}
      </Flex>
    </Flex>
  );
};
