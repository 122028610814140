import type React from "react";

import type { LucideProps } from "lucide-react";
import {
  ArrowLeftRightIcon,
  CircleDotIcon,
  CoinsIcon,
  FileCheck2Icon,
  FileInputIcon,
  FileMinus2Icon,
  FileX2Icon,
  HeartHandshakeIcon,
  KeyIcon,
  Link2Icon,
  MailCheckIcon,
  MailOpenIcon,
  PaperclipIcon,
  PhoneCallIcon,
  UploadCloudIcon,
  UserIcon,
} from "lucide-react";
import { theme } from "twin.macro";

import type {
  ILeadActivity,
  IPartnerActivity,
  PrismaLeadActivityType,
  PrismaPartnerActivityType,
} from "@obd/common";

export const getActivityTypeMeta = (
  activityType: PrismaLeadActivityType | PrismaPartnerActivityType,
  originalActivity?: IPartnerActivity | ILeadActivity
) => {
  let icon: React.FC<LucideProps> | null = null;
  let bg = theme("colors.gray.150");
  let fg = theme("colors.gray.600");
  let activityName = "?";
  let actionName = "";
  let gender = "o";
  const expiryDate = "";

  switch (activityType) {
    case "note":
      icon = PaperclipIcon;
      bg = "#EDF6FF";
      fg = "#006ADC";
      activityName = "Nota";
      actionName = "añadió una";
      gender = "a";
      break;
    case "call":
      icon = PhoneCallIcon;
      activityName = "Llamada";
      actionName = "realizó una";
      gender = "a";
      bg = "#EEFADC";
      fg = "#5D770D";
      break;
    case "email":
      icon = MailCheckIcon;
      activityName = "Email";
      actionName = "envió un";
      bg = "#F5F2FF";
      fg = "#5746AF";
      break;
    case "emailReceived":
      icon = MailOpenIcon;
      activityName = "Email";
      actionName = "recibió un";
      bg = "#F5F2FF";
      fg = "#5746AF";
      break;
    case "commercialVisit":
      icon = HeartHandshakeIcon;
      activityName = "Visita comercial";
      actionName = "programó una";
      gender = "a";
      bg = "#FEEEF8";
      fg = "#CD1D8D";
      break;
    case "statusChange":
      icon = CircleDotIcon;
      activityName = "Estado";
      actionName = "cambió el";
      bg = "#FFF1E7";
      fg = "#BD4B00";
      break;
    case "stepChange":
      icon = ArrowLeftRightIcon;
      activityName = "Paso";
      actionName = "cambió el";
      bg = "#E7F9FB";
      fg = "#0C7792";
      break;
    case "import":
      icon = UploadCloudIcon;
      activityName = "Importación";
      actionName = "hizo una";
      bg = "#F0F4FF";
      fg = "#3451B2";
      break;
    case "partnerLink":
      icon = Link2Icon;
      activityName = "Cliente";
      actionName = "enlazó un";
      fg = "#0C7792";
      bg = "#E7F9FB";
      break;
    case "partnerPurchase":
      icon = CoinsIcon;
      activityName = "Compra";
      actionName = "registró una";
      fg = "#18794E";
      bg = "#E9F9EE";
      break;
    case "signupSent":
      icon = FileInputIcon;
      activityName = "Apertura de cuenta";
      actionName = "solicitó la";
      fg = "#1B9976";
      bg = "#E9F9F4";
      break;
    case "signupCompleted":
      icon = FileCheck2Icon;
      activityName = "Documentos de alta";
      actionName = "firmó los";
      fg = "#1B9976";
      bg = "#E9F9F4";
      break;
    case "signupDeclined":
      icon = FileX2Icon;
      activityName = "Apertura de cuenta";
      actionName = "rechazó la";
      fg = "#DC3D43";
      bg = "#FFEFEF";
      break;
    case "signupVoided":
      icon = FileMinus2Icon;
      activityName = "Apertura de cuenta";
      actionName = "anuló la";
      fg = "#DC3D43";
      bg = "#FFEFEF";
      break;
    case "leadAssigned":
      icon = UserIcon;
      actionName = "asignó a";
      activityName = "";
      fg = "#067A6F";
      bg = "#E7F9F5";
      break;
    case "leadOwned":
      icon = KeyIcon;
      actionName = `${originalActivity?.extra ? "" : "se "}convirtió en`;
      activityName = "propietario";
      fg = "#946800";
      bg = "#FFFBD1";
      break;
  }

  return {
    icon,
    activityName,
    actionName,
    bg,
    fg,
    expiryDate,
    gender,
  };
};

type ActivityKind = "partner" | "lead";
type ActivityKindToType = { lead: ILeadActivity; partner: IPartnerActivity };

export const isActivityKind = <T extends ActivityKind>(
  activity: ILeadActivity | IPartnerActivity,
  kind: T
): activity is ActivityKindToType[T] => {
  return (
    ("lead" in activity && kind === "lead") ||
    ("partner" in activity && kind === "partner")
  );
};
